<template>
  <div class="compelte-page">
    <div class="compelte-wrap">
      <!-- <div class="img-wrap">
        <img style="opacity: 0.6" src="@/assets/Images/c2.png" alt="" />
        <img class="bottle" src="@/assets/Images/c1.png" alt="" />
      </div>

      <div v-if="hasForm" class="text">
        測驗已完成，您尚有測驗未完成<br />

        您可點選繼續作答按鍵，進入下一份測驗，<br />

        或是關閉此視窗結束此次測驗
      </div>

      <div v-if="!hasForm" class="text">
        測驗已完成，您可關閉此視窗結束此次測驗
      </div>-->

      <TraitComponent v-if="!isInterviewer" :patternModal="patternModal" />

      <div v-if="!hasForm && isInterviewer" class="text">
        您已成功完成測驗，感謝您的填答

        <!-- <div class="button-wrap">
          <button type="button" class="button" @click.prevent="closeTab()">關閉</button>
        </div> -->
      </div>

      <div class="button-wrap">
        <button type="button" class="button" v-if="hasForm" @click.prevent="goQuestion()">
          繼續作答
        </button>
        <!-- <button type="button" class="button complete" v-print="'#infos-table'">
          列印
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import TraitComponent from "@/components/Result/TraitComponent";
import print from "vue-print-nb";

export default {
  directives: {
    print,
  },

  data() {
    return {
      hasForm: false,
      patternModal: null,
      isInterviewer: false, //是否為面試人員
    };
  },
  components: {
    TraitComponent,
  },
  methods: {
    getData() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Answer`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.hasForm = false;
          } else {
            this.hasForm = true;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    goHome() {
      this.$router.push({ path: "home" });
    },
    goQuestion() {
      this.$router.push({ path: "Question" });
    },

    closeTab() {
      // 嘗試關閉當前瀏覽器頁籤
      window.close();

      // 提醒用戶此功能的限制
      if (!window.closed) {
        this.$notify({
          title: "提醒",
          message: "瀏覽器安全限制，無法關閉當前頁籤。",
          type: "warning",
          duration: 0,
        });
      }
    },
  },
  created() {
    this.getData();
    this.patternModal = JSON.parse(localStorage.getItem("TestResult"));

    let roles = localStorage.getItem("RoleName");
    let targetRole = "面試人員";

    // 創建正規表達式來精確匹配目標角色
    let regex = new RegExp(`(^|,)${targetRole}($|,)`);

    if (regex.test(roles)) {
      this.isInterviewer = true;
    } else {
      this.isInterviewer = false;
    }
  },
};
</script>

<style lang="scss">
.compelte-page {
  height: 100vh;
  background-image: url("~@/assets/Images/37130.jpg");
  background-size: cover;
  position: relative;
  overflow-y: auto;

  //@media screen and (max-width: 767px) {
  //  margin-top: -50px;
  //}
  &:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 41, 126, 0.6);
    z-index: 1;
  }

  .compelte-wrap {
    position: relative;
    z-index: 3;
    text-align: center;
    padding: 60px;

    @media (max-width: 767px) {
      padding: 5px;
    }

    .img-wrap {
      display: inline-block;
      position: relative;
      margin-bottom: 40px;
      .bottle {
        position: absolute;
        left: 0;
        top: 0;

        animation: waving 2.8s linear infinite;
      }
      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 80%;
        height: 80%;
        border-radius: 50%;
        z-index: -1;
        opacity: 0.15;
      }
    }

    .text {
      color: #ffd13b;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 40px;
    }

    .button-wrap {
      .button {
        margin: 5px;
        background-color: #d88316;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        text-decoration: none;
        border: none;
        cursor: pointer;
      }
    }
  }
}

@keyframes waving {
  0% {
    top: -5px;
  }
  50% {
    top: 5px;
  }
  100% {
    top: -5px;
  }
}
</style>
